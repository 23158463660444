<template>
  <div
    class="z_index_100 width_100 align_items_center display_flex container_navigation"
    id="navigation"
  >
    <div
      class="align_items_center width_100 justify_content_space_between gap_4_5 display_flex"
    >
      <img
        @click="$router.push({ name: 'homePage' })"
        class="logo_pladcon cursor_pointer"
        :src="urlImg"
        alt="Logo"
      />

      <div
        class="display_flex display_none_mobile align_items_center height_fit_content gap_4_5"
      >
        <CustomLink v-for="link in links" :key="link.to" :link="link" />
        <a
          class="text_navigation text_wrap_nowrap"
          @click="$root.modalFormVisible.value = true"
        >
          Get in touch
        </a>
        <a
          href="https://calendly.com/magastudios/where-ideas-meet-expertise"
          target="_blank"
          class="button button_navigation"
        >
          Exploratory call
        </a>
      </div>
      <div
        id="link_navigation"
        class="display_flex navigation_link align_items_center height_fit_content gap_4_5"
      >
        <div v-for="link in links" :key="link.to">
          <CustomLink @click="navigationVisible" :link="link" />
        </div>
        <div class="last_childnavigation_link">
          <a
            class="text_navigation text_wrap_nowrap"
            @click="$root.modalFormVisible.value = true"
          >
            Get in touch
          </a>
        </div>
        <a
          href="https://calendly.com/magastudios/where-ideas-meet-expertise"
          target="_blank"
          class="button button_mobile"
        >
          Exploratory call
        </a>
        <span class="icon_select_menu"></span>
        <span class="border_menu-1"></span>
        <span class="border_menu-2"></span>
        
      </div>

      <span
        id="menu_dropdown"
        @click="navigationVisible"
        class="icon-MenuNavCel1 color_primary font_size_2_5 display_none_desk"
      ></span>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, onBeforeUnmount, onMounted, ref } from "vue";
export default {
  data() {
    return {
      links: [
        {
          to: "homePage",
          name: "Home",
        },
        {
          to: "projectsPage",
          name: "Our work",
        },
        {
          to: "aboutPage",
          name: "About us",
        },
      ],
    };
  },

  setup() {
    let urlImg = ref(
      require("../../../assets/NewWeb/LogoMaga/MAGA_LOGO_COLOR.png")
    );

    const navigationVisible = () => {
      const elemetNavigation = document.querySelector("#link_navigation");
      if (elemetNavigation.classList.contains("navigation_visible")) {
        elemetNavigation.classList.remove("navigation_visible");
      } else {
        elemetNavigation.classList.add("navigation_visible");
      }
    };

    window.addEventListener("scroll", () => {
      const element = document.getElementById("navigation");
      const scrollPosition = window.scrollY;
      if (scrollPosition >= 150) {
        element.classList.add("navigation_fixed");
        urlImg.value = require("../../../assets/NewWeb/LogoMaga/MAGA_LOGO_WHITE.png");
      } else {
        element.classList.remove("navigation_fixed");
        urlImg.value = require("../../../assets/NewWeb/LogoMaga/MAGA_LOGO_COLOR.png");
      }
    });

    onMounted(() => {
      window.addEventListener("click", handleClickOutside);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("click", handleClickOutside);
    });

    const handleClickOutside = (event) => {
      const divSelectMenu = document.getElementById("link_navigation");
      const iconMenu = document.getElementById("menu_dropdown");
      if (
        divSelectMenu &&
        !divSelectMenu.contains(event.target) &&
        iconMenu &&
        !iconMenu.contains(event.target)
      ) {
        if (divSelectMenu.classList.contains("navigation_visible")) {
          divSelectMenu.classList.remove("navigation_visible");
        }
      }
    };

    return {
      navigationVisible,
      urlImg,
    };
  },

  components: {
    CustomLink: defineAsyncComponent(() => import("./customLink.vue")),
  },
};
</script>

<style scoped>
.button_navigation {
  background: none;
  color: var(--primary);
  border: solid 0.25rem var(--primary);
}
</style>
