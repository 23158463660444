<template>
  <div class="container_pages marg_top_13rem pdng_top_botton2 back_prymary">
    <div class="display_grid justify_items_center gap_8">
      <div
        class="gap_11 flex_direction_column_mobile align_items_center_mobile display_flex"
      >
        <div class="gap_7_3 display_flex flex_direction_column_mobile">
          <div
            class="gap_2_4 height_fit_content justify_items_center_mobile display_grid"
          >
            <img
              class="width_13"
              src="../../assets/NewWeb/LogoMaga/MAGA_LOGO_WHITE.png"
              alt="PladconLogoClaro"
            />
          </div>

          <span class="section_line display_none_mobile"></span>

          <div
            class="gap_3_5 height_fit_content justify_items_center_mobile display_grid"
          >
            <p class="paragraph color_white text_weight_bold">
              Contact us or call us
            </p>
            <div class="display_grid gap_1">
              <span class="paragraph color_white">+57 300 727 43 91</span>
              <span class="paragraph color_white">+57 315 358 75 08</span>
            </div>
          </div>
        </div>

        <div
          class="gap_3_5 height_fit_content justify_items_center_mobile display_grid"
        >
          <p class="paragraph color_white text_weight_bold">Locations</p>
          <div class="display_grid justify_items_center_mobile">
            <span class="paragraph color_white">Medellín, CO</span>
            <span class="paragraph color_white">
              Calle 27 Sur # 27 B - 87</span
            >
          </div>
          <div class="display_grid justify_items_center_mobile">
            <span class="paragraph color_white">Barranquilla, CO</span>
            <span class="paragraph color_white">Cra 56 # 96 - 20</span>
          </div>
        </div>

        <div
          class="display_grid justify_items_center_mobile justify_content_space_between"
        >
          <div
            class="gap_3_5 height_fit_content justify_items_center_mobile display_grid"
          >
            <p class="paragraph color_white text_weight_bold">Contact</p>

            <div
              class="gap_4_5 gap_4_mobile justify_items_center_mobile display_grid"
            >
              <span class="paragraph color_white"
                >sales@maga<span class="color_blue">studios</span>co.com</span
              >

              <div class="gap_3 align_items_center display_flex">
                <a
                  href="https://www.linkedin.com/company/maga-studios/?viewAsMember=true"
                  target="_blank"
                  class="icon-LinkedIn1 text_decoration_none color_blue font_size_2_5"
                ></a>
                <a
                  href="https://www.instagram.com/maga_studios/"
                  target="_blank"
                  class="icon-Instagram2 text_decoration_none color_blue font_size_2_5"
                ></a>
                <a
                  href="https://twitter.com/StudiosMaga"
                  target="_blank"
                  class="icon-X text_decoration_none color_blue font_size_2_5"
                ></a>
                <a
                  href="https://api.whatsapp.com/send/?phone=573007274391"
                  target="_blank"
                  class="icon-WhatsApp text_decoration_none color_blue font_size_2_5"
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text_align_center_mobile">
        <span class="paragraph color_white">2024</span>
        <span class="paragraph color_blue mrg_5_right">
          Maga Studios S.A.S.
        </span>
        <span class="paragraph display_none_mobile color_white">
          All rights reserved - Privacy Policy
        </span>
        <span class="paragraph display_none_desk color_white">
          All rights <br />
          reserved - Privacy Policy
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { topMax } from "@/utilities";

export default {
  setup() {
    return {
      topMax,
    };
  },
};
</script>

<style scoped>

.mrg_5_right {
  margin-right: 0.5rem;
}

.section_line {
  height: 100%;
  width: 0.15rem;
  background: var(--white);
}



.gap_8 {
  gap: 8rem !important;
}
@media (max-width: 1250px) {


  .container_pages > div {
    gap: 6.5rem !important;
  }

  .align_items_center_mobile {
    align-items: center;
  }


  .gap_4_mobile {
    gap: 4rem;
  }

  .justify_items_center_mobile {
    justify-items: center;
  }

}
</style>