<template>
  <div class="modal">
    <div class="position_relative">
      <span
        @click="close()"
        class="icon-close display_grid cursor_pointer place_items_center"
      ></span>
      <img src="../../assets/NewWeb/Images/Perfect.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  emits: ["actionClose"],

  methods: {
    close() {
      this.$emit("actionClose");
    },
  },

};
</script>

<style scoped>
.modal > div {
  background: none;
  border: none;
  width: auto;
}

img {
  width: 45.4746rem;
}

.modal > div > span {
  top: 7rem;
  right: 11rem;
}

@media (max-width: 1250px) {
  img {
    width: 34.8rem;
  }
}
</style>

