<template>
  <div class="modal">
    <div class="position_relative">
      <span
        @click="close()"
        class="icon-close display_grid cursor_pointer place_items_center"
      ></span>
      <div class="display_grid gap_8">
        <h4 class="titleHeader font_size_3_8">How can we help you?</h4>
        <form id="form" class="display_grid gap_5_5">
          <div class="display_flex flex_direction_column_mobile gap_3">
            <div class="display_grid width_25p width_100">
              <label class="paragraph" for="">Name *</label>
              <input
                name="name"
                id="name"
                type="text"
                v-model="formData.name"
                :class="{ error: formErrors.name }"
              />
            </div>
            <div class="display_grid width_25p width_100">
              <label class="paragraph" for="">Company *</label>
              <input
                type="text"
                v-model="formData.company"
                :class="{ error: formErrors.company }"
                name="company"
                id="company"
              />
            </div>
            <div class="display_grid width_25p width_100">
              <label class="paragraph" for="">Country *</label>
              <input
                type="text"
                name="country"
                id="country"
                v-model="formData.country"
                :class="{ error: formErrors.country }"
              />
            </div>
            <div class="display_grid width_25p width_100">
              <label class="paragraph" for="">Email *</label>
              <input
                type="text"
                name="email"
                id="email"
                v-model="formData.email"
                :class="{ error: formErrors.email }"
              />
            </div>
          </div>

          <div class="display_flex flex_direction_column_mobile gap_3">
            <div class="display_grid width_25p width_100">
              <label class="paragraph" for="">Phone *</label>
              <input
                type="text"
                name="phone"
                id="phone"
                v-model="formData.cellPhone"
                :class="{ error: formErrors.cellPhone }"
              />
            </div>

            <div class="display_grid width_100">
              <label class="paragraph" for="">Tell us about your idea</label>
              <textarea name="idea" id="idea"></textarea>
            </div>
          </div>
        </form>

        <div
          class="gap_5 gap_1_3_mobile align_items_center flex_direction_column_reverse_mobile display_flex"
        >
          <p class="paragraph text_align_justify_mobilr">
            By clicking 'send,' you accept Maga Studios S.A.S.'s
            <span class="text_weight_bold">data processing policies.</span>
          </p>
          <button id="button" @click="senform" class="button">
            {{ button }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  emits: ["actionClose", "actionOk"],
  setup(_, { emit }) {
    const close = () => {
      emit("actionClose");
    };

    let formData = ref({
        name: "",
        company: "",
        email: "",
        cellPhone: "",
        idea: "",
        country: "",
      }),
      formErrors = ref({
        name: false,
        company: false,
        email: false,
        country: false,
        cellPhone: false,
      }),
      button = ref("Send");

    const senform = () => {
      const requiredFields = [
        "name",
        "company",
        "country",
        "email",
        "cellPhone",
      ];

      const isFormValid = requiredFields.every(
        (field) => formData.value[field] !== ""
      );

      for (const key in formData.value) {
        if (formData.value[key] === "" && key !== "idea") {
          formErrors.value[key] = true;
        } else {
          formErrors.value[key] = false;
        }
      }

      if (!isFormValid) {
        return;
      }
      const serviceID = "default_service";
      const templateID = "template_r1n747c";

      button.value = "Sending";

      const formElement = document.getElementById("form");
      /* eslint-disable no-undef */
      emailjs.sendForm(serviceID, templateID, formElement).then(
        () => {
          button.value = "Sent";
          emit("actionOk");
          close();
        },
        (err) => {
          button.value = "Send";
          alert(JSON.stringify(err));
        }
      );
    };

    return {
      close,
      senform,
      formData,
      button,
      formErrors,
    };
  },
};
</script>

<style scoped>
@media (min-width: 1250px) {
  .width_25p {
    width: 25%;
  }
}

@media (max-width: 1250px) {
  .modal > div {
    position: absolute;
    top: 0;
    bottom: 0;
    border-radius: 0;
    height: auto;
    border: none;
    padding: 2.5rem;
    overflow: auto;
    padding-top: 9.1rem;
    left: 0;
    right: 0;
    width: auto;
  }

  .button {
    min-width: 100%;
    max-width: 100%;
    min-height: 5.8rem;
  }

  .text_align_justify_mobilr {
    text-align: center;
  }

  .modal > div > span {
    top: 3rem;
    right: 3.7rem;
  }

  .gap_8 {
    gap: 4.9rem;
  }

  .gap_1_3_mobile {
    gap: 3rem;
  }
}
</style>