<template>
  <navigationMaga />
  <router-view />
  <footer-page v-show="!showOnSpecificPath" />

  <transition name="modal-fade">
    <div v-if="$root.modalFormVisible.value">
      <modalhow-can-we-help-you
        @actionOk="$root.modalPerfecVisible.value = true"
        @actionClose="$root.modalFormVisible.value = false"
      />
    </div>
  </transition>

  <ChatMaga @antionClose="chatVisible" />

  <transition name="modal-fade">
    <div v-if="$root.modalPerfecVisible.value">
      <submitted-form @actionClose="$root.modalPerfecVisible.value = false" />
    </div>
  </transition>

  <div class="container_chat_icon cursor_pointer">
    <span class="counter" v-if="token_countMessage_total_ref !== 0">{{
      token_countMessage_total_ref
    }}</span>
    <span @click="chatVisible()" class="icon-Chat"></span>
  </div>
</template>

<script>
import FooterPage from "./pages/components/footerPage.vue";
import navigationMaga from "./pages/components/navigation/navigationMaga.vue";
import { topMax } from "./utilities";
import { useRoute } from "vue-router";
import ModalhowCanWeHelpYou from "./pages/components/modalhowCanWeHelpYou.vue";
import SubmittedForm from "./pages/components/SubmittedForm.vue";
import { ref, watch } from "vue";
import useChat from "./pages/composables/useChat";
import ChatMaga from "./pages/components/chatMaga.vue";

export default {
  components: {
    navigationMaga,
    FooterPage,
    ModalhowCanWeHelpYou,
    SubmittedForm,
    ChatMaga,
  },

  setup() {
    const route = useRoute();
    let showOnSpecificPath = ref(false);

    let {
      loandDatabase,
      countChatAdviser_2,
      token_countMessage_total_ref,
      chatVisible,
      getRandomId,
    } = useChat();

    const methodLoadChat = async () => {};

    if (!localStorage.getItem("token_chat")) {
      localStorage.setItem("token_chat", getRandomId());
    }
    loandDatabase(methodLoadChat);
    countChatAdviser_2();

    watch(
      () => route.path,
      (newPath) => {
        showOnSpecificPath.value = newPath === "/ContactUs";
        topMax();
      }
    );

    return {
      showOnSpecificPath,
      token_countMessage_total_ref,
      chatVisible,
    };
  },
};
</script>

<style>
</style>
